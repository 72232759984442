import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17f46aa6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card role-menu-card card-default" }
const _hoisted_2 = { class: "card-header role-menu-header" }
const _hoisted_3 = { class: "card-body role-menu-list" }
const _hoisted_4 = {
  key: 0,
  class: "form-group role-menu-items"
}

export function render(_ctx, _cache) {
  const _component_app_checkbox = _resolveComponent("app-checkbox")
  const _component_app_sub_menu_item = _resolveComponent("app-sub-menu-item")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_app_checkbox, {
        class: "custom-themes text-gray",
        type: "custom",
        "data-path": _ctx.menuItem.path,
        onChange: _cache[0] || (_cache[0] = $event => (_ctx.change($event, 2))),
        modelValue: _ctx.value[_ctx.menuItem.path],
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.value[_ctx.menuItem.path]) = $event))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.menuItem.title), 1)
        ]),
        _: 1
      }, 8, ["data-path", "modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.menuItem.type==='menu')
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItem.action, (action, a) => {
              return (_openBlock(), _createBlock(_component_app_checkbox, {
                class: "custom-themes mr-2",
                type: "custom",
                "data-path": _ctx.menuItem.path + '|' + action.code,
                onChange: _cache[2] || (_cache[2] = $event => (_ctx.change($event, 1))),
                modelValue: _ctx.value[_ctx.menuItem.path + '|' + action.code],
                "onUpdate:modelValue": $event => ((_ctx.value[_ctx.menuItem.path + '|' + action.code]) = $event),
                key: a
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(action.name), 1)
                ]),
                _: 2
              }, 1032, ["data-path", "modelValue", "onUpdate:modelValue"]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (_ctx.menuItem.type==='module')
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.menuItem.children, (item3, i3) => {
            return (_openBlock(), _createBlock(_component_app_sub_menu_item, {
              modelValue: _ctx.value,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.value) = $event)),
              key: i3,
              menuItem: item3,
              change: _ctx.change,
              left: 1
            }, null, 8, ["modelValue", "menuItem", "change"]))
          }), 128))
        : _createCommentVNode("", true)
    ])
  ]))
}