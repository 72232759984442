import { Options } from "vue-class-component";
import BaseTableInterface from "@/mixins/BaseTableInterface";
import BaseTableMixins from "@/mixins/BaseTable";
import DraggableListview from "@/modules/draggable-listview/draggable-listview.vue";
import SelectLevel from "@/modules/select-level/select-level.vue";
import RoleMenuView from "@/modules/role-menu-view/role-menu-view.vue";
import RoleTableItem from "@/entity/system/master/RoleTableItem";
import API from "@/api/system/master/role";
import OrgTypeAPI from "@/api/system/master/org-type";

@Options({
    name: "app-system-master-role",
    components: {
        "app-select-level": SelectLevel,
        "app-draggable-listview": DraggableListview,
        "app-role-menu-view": RoleMenuView,
    },
})
export default class Role extends BaseTableMixins<RoleTableItem> implements Partial<BaseTableInterface<RoleTableItem>> {
    public created(): void {
        this.setInterface(this);
        this.tableRequestItem.lockItem = this.getLockItem();
        this.editTableItem = new RoleTableItem();
        this.editModalItem = new RoleTableItem();
    }

    //锁定列数据实体
    public getLockItem(): any {
        return {
            orgTypeId: {
                type: "eq",
                name: "org_type_id",
                title: "组织类型",
                parent: null,
                value: null,
                openFn: this.orgTypeIdLevelModalOpen,
            },
        };
    }

    public activated(): void {
        this.getList(1);
    }

    //后端API
    public getApi(): any {
        return API;
    }

    //组织类型API
    get getOrgTypeAPI(): any {
        return OrgTypeAPI;
    }

    // //列表回调
    // public listCallback(items: Array<RoleTableItem>): void {
    //     return;
    // }

    //添加弹窗配置
    public addModalSetting(): void {
        this.editTableItem = new RoleTableItem();
        this.editModalItem = new RoleTableItem();
        this.editModalItem.roleStatus = 1;
        this.editModalTitle = "添加角色";
        this.editModalSubmitButtonName = "添加";
    }
    //添加提交检测
    public addSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.roleName)) return "角色名称不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.roleStatus)) return "角色状态不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.orgTypeId)) return "组织类型不能为空！";
        return null;
    }
    // //添加回调
    // public addModalCallback(res: any): void {
    //     return;
    // }

    //修改弹窗配置
    public modModalSetting(): void {
        this.editModalTitle = "修改角色 (" + this.editModalItem.roleName + ")";
        this.editModalSubmitButtonName = "修改";
    }
    //修改提交检测
    public modSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.roleId)) return "角色ID不能为空！";
        return this.addSubmitChecking();
    }
    // //修改回调
    // public modModalCallback(res: any): void {
    //     return;
    // }

    //删除提示框配置
    public delAlertConfig(): any {
        return {
            type: "okAndNo",
            title: "删除角色",
            message: "角色名称: " + this.editModalItem.roleName + "<br>请确认删除？",
        };
    }
    // //删除提示框配置
    // public delAlertSetting(): void {
    //     return;
    // }
    //删除提示框提交检测
    public delSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.roleId)) return "角色ID不能为空！";
        return null;
    }
    // //删除回调
    // public delAlertCallback(res: any): void {
    //     return;
    // }

    //状态提示框配置
    public statusAlertConfig(): any {
        return {
            title: "角色状态",
            message: "角色名称: " + this.editModalItem.roleName + "<br>请确认修改状态？",
            submitButtonName: this.editModalItem.roleStatus === 1 ? "启用" : "禁用",
        };
    }
    //状态切换请求配置
    public toggleStatusSetting(): void {
        this.editModalItem.roleStatus = this.editModalItem.roleStatus === 1 ? 2 : 1;
    }
    //状态切换提交检测
    public toggleStatusSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.roleId)) return "角色ID不能为空！";
        return null;
    }
    // //状态切换回调
    // public toggleStatusCallback(): void {
    //     return;
    // }

    //排序弹窗配置
    public sortModalSetting(): void {
        this.editSortBaseTitle = "角色排序";
    }

    //角色菜单选择
    private roleMenuModalOpen(item: RoleTableItem) {
        (this.$refs.roleMenuModal as any).openModal(item);
    }

    //orgTypeId搜索字段
    private orgTypeIdLevelSearchField: any = [
        { field: "org_type_name", name: "名称" },
        { field: "org_type_remark", name: "说明" },
    ];
    //orgTypeId层级选择器打开
    public orgTypeIdLevelModalOpen() {
        (this.$refs.orgTypeIdLevel as any).openModal();
    }
    //orgTypeId层级选择器提交
    public async orgTypeIdLevelFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem().orgTypeId : item;
        this.tableRequestItem.lockItem.orgTypeId.value = curItem.value;
        this.tableRequestItem.lockItem.orgTypeId.title = curItem.title;
        this.tableRequestItem.lockItem.orgTypeId.parent = curItem.parent;
        await this.getList(1);
        return Promise.resolve();
    }

    get orgTypeIdSelectApi() {
        return {
            api: (query: any) => OrgTypeAPI.idSelect(query),
            status: "org_type_status",
            name: "org_type_name",
            id: "org_type_id",
            cid: this.editModalItem.orgTypeId,
            extend: true,
        };
    }
}
