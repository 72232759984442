import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0addfb2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-3" }
const _hoisted_3 = { class: "nav flex-column nav-tabs h-100" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "col-9" }
const _hoisted_6 = { class: "tab-content" }
const _hoisted_7 = { class: "card role-menu-card card-default" }
const _hoisted_8 = { class: "card-header role-menu-header" }
const _hoisted_9 = { class: "card-body role-menu-list" }
const _hoisted_10 = {
  key: 0,
  class: "form-group role-menu-items"
}
const _hoisted_11 = { class: "card-header role-menu-header" }
const _hoisted_12 = { class: "card-body role-menu-list" }
const _hoisted_13 = {
  key: 0,
  class: "form-group role-menu-items"
}

export function render(_ctx, _cache) {
  const _component_app_checkbox = _resolveComponent("app-checkbox")
  const _component_app_sub_menu_item = _resolveComponent("app-sub-menu-item")
  const _component_app_modal = _resolveComponent("app-modal")

  return (_openBlock(), _createBlock(_component_app_modal, {
    id: "menuSelectModal",
    ref: "menuSelectModal",
    title: '配置角色菜单: '+this.roleItem.roleName,
    size: "lg",
    loading: _ctx.isLoading,
    submitFunction: _ctx.submitFunction
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item, i) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["nav-link", {'active': this.tabId === i}]),
                onClick: $event => (this.tabId = i)
              }, _toDisplayString(item.title), 11, _hoisted_4))
            }), 256))
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item1, i1) => {
              return (_openBlock(), _createElementBlock("div", {
                key: i1,
                class: _normalizeClass(["tab-pane text-left animate__animated", {'animate__fadeIn active': this.tabId === i1}])
              }, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_app_checkbox, {
                      class: "custom-themes text-gray",
                      type: "custom",
                      "data-path": item1.path,
                      onChange: _cache[0] || (_cache[0] = $event => (_ctx.onCheckChange($event, 2))),
                      modelValue: _ctx.menuSelected[item1.path],
                      "onUpdate:modelValue": $event => ((_ctx.menuSelected[item1.path]) = $event)
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item1.title), 1)
                      ]),
                      _: 2
                    }, 1032, ["data-path", "modelValue", "onUpdate:modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    (item1.type==='menu')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item1.action, (action1, a1) => {
                            return (_openBlock(), _createBlock(_component_app_checkbox, {
                              class: "custom-themes mr-2",
                              type: "custom",
                              "data-path": item1.path + '|' + action1.code,
                              onChange: _cache[1] || (_cache[1] = $event => (_ctx.onCheckChange($event, 1))),
                              modelValue: _ctx.menuSelected[item1.path + '|' + action1.code],
                              "onUpdate:modelValue": $event => ((_ctx.menuSelected[item1.path + '|' + action1.code]) = $event),
                              key: a1
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(action1.name), 1)
                              ]),
                              _: 2
                            }, 1032, ["data-path", "modelValue", "onUpdate:modelValue"]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true),
                    (item1.type==='module')
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(item1.children, (item2, i2) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: i2,
                            class: "card role-menu-card card-default"
                          }, [
                            _createElementVNode("div", _hoisted_11, [
                              _createVNode(_component_app_checkbox, {
                                class: "custom-themes text-gray",
                                type: "custom",
                                "data-path": item2.path,
                                onChange: _cache[2] || (_cache[2] = $event => (_ctx.onCheckChange($event, 2))),
                                modelValue: _ctx.menuSelected[item2.path],
                                "onUpdate:modelValue": $event => ((_ctx.menuSelected[item2.path]) = $event)
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item2.title), 1)
                                ]),
                                _: 2
                              }, 1032, ["data-path", "modelValue", "onUpdate:modelValue"])
                            ]),
                            _createElementVNode("div", _hoisted_12, [
                              (item2.type==='menu')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item2.action, (action2, a2) => {
                                      return (_openBlock(), _createBlock(_component_app_checkbox, {
                                        class: "custom-themes mr-2",
                                        type: "custom",
                                        "data-path": item2.path + '|' + action2.code,
                                        onChange: _cache[3] || (_cache[3] = $event => (_ctx.onCheckChange($event, 1))),
                                        modelValue: _ctx.menuSelected[item2.path + '|' + action2.code],
                                        "onUpdate:modelValue": $event => ((_ctx.menuSelected[item2.path + '|' + action2.code]) = $event),
                                        key: a2
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(action2.name), 1)
                                        ]),
                                        _: 2
                                      }, 1032, ["data-path", "modelValue", "onUpdate:modelValue"]))
                                    }), 128))
                                  ]))
                                : _createCommentVNode("", true),
                              (item2.type==='module')
                                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(item2.children, (item3, i3) => {
                                    return (_openBlock(), _createBlock(_component_app_sub_menu_item, {
                                      modelValue: _ctx.menuSelected,
                                      "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.menuSelected) = $event)),
                                      key: i3,
                                      menuItem: item3,
                                      left: 1,
                                      change: _ctx.onCheckChange
                                    }, null, 8, ["modelValue", "menuItem", "change"]))
                                  }), 128))
                                : _createCommentVNode("", true)
                            ])
                          ]))
                        }), 128))
                      : _createCommentVNode("", true)
                  ])
                ])
              ], 2))
            }), 128))
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["title", "loading", "submitFunction"]))
}